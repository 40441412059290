@import-normalize; /* bring in normalize.css styles */

body {
  width: 100vw;
  height: 100vh;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
svg {
  fill: currentColor;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h6,
h5,
h4,
h3,
h2,
h1,
p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.display-heavy {
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
}
.display {
  font-weight: normal;
  font-size: 36px;
  line-height: 48px;
}
.headline-heavy {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}
.headline {
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
}
.title-heavy {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
.title {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}
.subheader-heavy {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.subheader {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.body-heavy {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.body {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
.caption-heavy {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.caption {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.small-heavy {
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}
.small {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
}
::-webkit-scrollbar {
  width: 8px;
  border: solid 1px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background: #8e8e8e6b;
  border-radius: 8px;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: white;
}
.ant-popover-inner-content {
  padding: 0;
}
.ant-popover-title {
  padding: 8px 20px;
}
.ant-table-filter-dropdown {
  min-width: 180px;
}
.total .ant-badge-count {
  background-color: var(--primary-color);
}
.ant-table-filter-dropdown-btns {
  flex-direction: row-reverse;
  padding: 8px 12px;
}
.ant-table-filter-dropdown-btns .ant-btn:first-child:not([disabled]) span {
  color: #2d3748;
}
.ant-table-filter-dropdown .ant-dropdown-menu-item {
  text-transform: capitalize;
}
*[class^="ant-collapse"] {
  padding: 0 !important;
  background-color: transparent;
}
.ant-collapse-item {
  padding: 16px 0 !important;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #f0f0f0;
}
