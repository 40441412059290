@import "~antd/dist/antd.less";

@primary-color: #14b8a6;
@info-color: #2684ff;
@success-color: #38a169;
@processing-color: #2fc8b3;
@error-color: #e53e3e;
@highlight-color: #e53e3e;
@warning-color: #ffc400;
@normal-color: #d9d9d9;
@white: #fff;
@black: #000;

@text-color: #2d3748;
@text-color-secondary: #718096;

@input-affix-margin: 8px;
@input-placeholder-color: #718096;

@heading-color: @text-color;

:root {
  --primary-color: #14b8a6;
}

