.maintenance {
  text-align: center;
  padding: 150px;
  font: 20px Helvetica, sans-serif;
  color: #2d3748;
}

.maintenance a {
  color: #14b8a6;
  text-decoration: none;
}
.maintenance a:hover {
  color: #2d3748;
  text-decoration: none;
}

.maintenance h1 {
  font-size: 50px;
  margin-bottom: 20px;
}

.maintenance article {
  display: block;
  text-align: left;
  width: 650px;
  margin: 0 auto;
}

.maintenance p {
  line-height: 24px;
  margin-bottom: 8px;
}
